<template>
  <v-row id="join" class="wrapper overflow-hidden text-center" align="center">
    <v-col cols="12">
      <v-card class="content transparent" flat>
        <h1>ARAMIZA KATIL</h1>
        <h2>HL2RP MACERAN SENİ BEKLİYOR!</h2>
        <v-btn href="https://discord.gg/WEZ8pdX6gc" target="_blank" class="mt-6 py-6 mx-2" width="220" color="primary">
          <v-icon class="mr-2">mdi-microphone-message</v-icon>Discord
        </v-btn>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>
export default {

}
</script>
<style lang="scss">
#join {
  .parallax {
    background-image: url("~@/assets/hl2rp_imgs/citadel.jpg");

    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.6);
    }
  }
}
</style>
