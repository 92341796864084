<template>
  <div id="team" class="wrapper overflow-hidden">
    <v-container>
      <v-card class="content transparent my-10" flat>
        <h1>TAKIM</h1>
        <h2 class="font-italic">DAHA İYİSİ İÇİN UĞRAŞIYORUZ!</h2>
        <div class="d-block d-lg-flex">
          <v-col>
            <v-list color="rgba(0,0,0,0.4)" style="height: 100%;">
              <h3 class="text-center">Willard Networks Türkiye</h3>
              <template v-for="(item, index) in wnt">
                <v-subheader v-if="item.header" :key="item.header" {{ item.header }}></v-subheader>

                <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

                <v-list-item v-else :key="item.name">
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center justify-space-between">{{ item.name }}
                      <v-chip class="ml-8" outlined x-small>{{ item.title }}</v-chip>
                    </v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-col>
            <v-list color="rgba(0,0,0,0.4)" style="height: 100%;">
              <h3 class="text-center">Willard Networks</h3>
              <template v-for="(item, index) in management">
                <v-subheader v-if="item.header" :key="item.header" {{ item.header }}></v-subheader>

                <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

                <v-list-item v-else :key="item.name">
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center justify-space-between">{{ item.name }}
                      <v-chip class="ml-8" outlined x-small>{{ item.title }}</v-chip>
                    </v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-col>
            <v-list color="rgba(0,0,0,0.4)" style="height: 100%;">
              <h3 class="text-center">Geliştiriciler</h3>
              <template v-for="(item, index) in devs">
                <v-subheader v-if="item.header" :key="item.header" {{ item.header }}></v-subheader>

                <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

                <v-list-item v-else :key="item.name">
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center justify-space-between">{{ item.name }}
                      <v-chip class="ml-8" outlined x-small>{{ item.title }}</v-chip>
                    </v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </div>
      </v-card>
    </v-container>
    <div class="parallax"></div>
  </div>
</template>
<script>
export default {
  data: () => ({
    management: [
      {
        avatar: 'avatars/atle.jpg',
        name: 'Atle',
        title: 'Willard Networks Direktörü'
      },
      {
        avatar: 'avatars/votton.jpg',
        name: 'Votton',
        title: 'Teknoloji Direktörü'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/legion.jpg',
        name: 'Legion',
        title: 'WN7 Direktörü (RU)'
      },
      {
        avatar: 'avatars/pixel.gif',
        name: 'Pixel',
        title: 'WNO Direktörü (SPA)'
      },
      {
        avatar: 'avatars/heat.png',
        name: 'Heat',
        title: 'OVC Direktörü (ROK)'
      },
      {
        avatar: 'avatars/charly.png',
        name: 'Charly',
        title: 'WN: Echo One Direktörü (FR)'
      },
      {
        avatar: 'avatars/abyss.png',
        name: 'Abyss',
        title: 'WN: Ordem17 (BR)'
      },
      {
        avatar: 'avatars/loxy.png',
        name: 'Loxy',
        title: 'WN: Veles (PL)'
      },
      {
        avatar: 'avatars/hayden.png',
        name: 'Hayden',
        title: 'WN: Short Stories (EN)'
      },
      {
        avatar: 'avatars/goose.png',
        name: 'Goose',
        title: 'WN: Mythos (EN HaloRP)'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/rad.jpg',
        name: 'Lord-Rad-X',
        title: 'Ana Sunucu Direktörü'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/noodle.jpg',
        name: 'Noodle',
        title: 'Ana Sunucu Konsey Üyesi'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/mint.jpg',
        name: 'M!nt',
        title: 'Ana Sunucu Konsey Üyesi'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/rabithunter.png',
        name: 'Rabithunter',
        title: 'Ana Sunucu Konsey Üyesi'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/denuke.png',
        name: 'Denuke',
        title: 'Ana Sunucu Konsey Üyesi'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/neonity.png',
        name: 'Neonity',
        title: 'Ana Sunucu Yetkili Direktörü'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/crab.png',
        name: 'Crab',
        title: 'Ana Sunucu Yetkili Direktörü'
      }
    ],
    devs: [
      {
        avatar: 'avatars/aspect.jpg',
        name: 'Aspect',
        title: 'Geliştirici'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/gr4ss.jpg',
        name: 'Gr4Ss',
        title: 'Sunucu Mühendisi'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/fruity.jpg',
        name: 'Fruity',
        title: 'Baş UI Geliştirici'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/geferon.jpg',
        name: 'Geferon',
        title: 'Geliştirici'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/Miliviu.jpg',
        name: 'Miliviu',
        title: 'Geliştirici'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/whitehole.jpg',
        name: 'Whitehole',
        title: 'Geliştirici'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/naast.jpg',
        name: 'Naast Kyram',
        title: 'Geliştirici'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/mango.png',
        name: 'Mango',
        title: 'Geliştirici'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/kawa.jpg',
        name: 'kawasaniac',
        title: '3D Modelci'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/wn.jpg',
        name: 'Topluluk',
        title: 'Oyuncular, Yetkililer ve katkıda bulunan diğer herkes'
      },
    ],
    wnt: [
      {
        avatar: 'avatars/wnt/enistein.webp',
        name: 'Enistein',
        title: 'WNT Direktörü & Geliştirici'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/wnt/davsan.webp',
        name: 'Davşan',
        title: 'Yetkili Direktörü'
      },
      {
        avatar: 'avatars/wnt/sinyorgordon.webp',
        name: 'Sinyor Gordon',
        title: 'Yetkili'
      },
      {
        avatar: 'avatars/wnt/beyin.webp',
        name: 'Beyin.Dll',
        title: 'Yetkili'
      },
      {
        avatar: 'avatars/wnt/revain.webp',
        name: 'Revain',
        title: 'Yetkili'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/wnt/lucifer.webp',
        name: 'Lucifer',
        title: 'Game Master'
      },
      {
        avatar: 'avatars/wnt/crat.webp',
        name: 'Crat',
        title: 'Game Master'
      },
      {
        avatar: 'avatars/wnt/glunar.webp',
        name: 'Glunar',
        title: 'Game Master'
      },
      { divider: true, inset: true },
      {
        avatar: 'avatars/wnt/neowolffe.webp',
        name: 'Neo-Wolffe',
        title: 'Mentor'
      },
      {
        avatar: 'avatars/wnt/adelart.webp',
        name: 'Adelart',
        title: 'Mentor'
      },
    ]
  })
}
</script>
<style lang="scss">
#team {
  .parallax {
    background-image: url("~@/assets/hl2rp_imgs/cps.jpg");

    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.85);
    }
  }
}
</style>
