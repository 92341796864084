<template>
  <v-row id="gameplay" class="wrapper overflow-hidden" align="center">
    <v-col class="pl-8 pl-md-3" cols="12" md="5" lg="3" offset-md="1" offset-lg="2">
      <v-card max-width="360" class="content mx-auto transparent" flat>
        <h1>HL2RP OYNANIŞ</h1>
        <h2 class="font-italic">ROLEPLAY İÇİN TASARLANDI</h2>
        <p>Yeniden tasarlanmış savaş sistemi, oynanış ve UI ile yepyeni bir Half-Life 2 Roleplay (HL2RP) sunucusuna kucak
          açın! Divinity Original Sin ve XCOM 2'den esinlenerek yeni bir HL2RPG janrası oluşturduk.</p>
        <p>Benzersiz yetenek ve nitelik sistemimiz, siz, oyuncularımıza daha önce hiçbir sunucuda deneyimlemediğiniz bir
          bakış açısı
          sağlayacak. Özgürlük esastır, ve biz sizlere gerçekten olmak istediğiniz karakteri oynama şansı sağlıyoruz.
          Zengin hikayemiz, eğlenceli özelliklerimiz ve ilginç mekaniklerimiz ile eşi benzeri olmayan bir evrene dalın,
          hayalinizdeki karakteri hayata geçirin.
        </p>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>
export default {
}
</script>
<style lang="scss">
#gameplay {
  .parallax {
    background-image: url("~@/assets/hl2rp_imgs/speed.jpg");

    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.7);
    }
  }
}
</style>
